@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --main-height: calc(100vh - 64px);
    --main-height-banner: calc(var(--main-height) - 44px);
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: var(--bluegray-200);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--bluegray-200);
    border-radius: .75rem;
    border: none;
  }

}

@layer components {
  .c-resize-handle {
    @apply bg-white border border-solid border-blue-400 opacity-0;
  }

  .active .c-resize-handle {
    @apply opacity-100;
  }

  .c-resize-handle--xy {
    @apply !w-2 !h-2;
  }

  .c-resize-handle--tr {
    @apply !-top-1 !-right-1;
  }

  .c-resize-handle--br {
    @apply !-bottom-1 !-right-1;
  }

  .c-resize-handle--bl {
    @apply !-bottom-1 !-left-1;
  }

  .c-resize-handle--tl {
    @apply !-top-1 !-left-1;
  }

  .c-resize-handle--x {
    @apply !w-1.5 !h-6 !top-1/2 !-mt-3;
  }

  .c-resize-handle--l {
    @apply !-left-1;
  }

  .c-resize-handle--r {
    @apply !-right-1;
  }

  .c-resize-handle--y {
    @apply !w-6 !h-1.5 !left-1/2 !-ml-3;
  }

  .c-resize-handle--t {
    @apply !-top-1;
  }

  .c-resize-handle--b {
    @apply !-bottom-1;
  }


  .btn-submit{
    @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800;
  }
  
}
