:root {
    --shadow-0: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
    --shadow-2: 5px 5px 20px 0px rgba(0, 0, 0, 0.05);
}

.frame {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
  
  .frame .frame-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .frame .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    padding: 0px 0px 8px;
    position: relative;
    width: 100%;
  }
  
  .frame .div-2 {
    align-items: center;
    align-self: stretch;
    background-color: white;
    border-radius: 8px;
    display: flex;
    height: 48px;
    justify-content: space-between;
    padding: 0px 24px;
    position: relative;
    width: 100%;
    cursor: pointer;
  }
  
  .frame .div-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .frame .div-wrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
    width: 100%;
  }
  
  .frame .text-wrapper {
    color: var(--newblack);
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame .close-wrapper {
    align-items: center;
    display: flex;
    gap: 10px;
    height: 24px;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 24px;
  }
  
  .frame .close-instance {
    height: 16px !important;
    margin-bottom: -6px !important;
    margin-left: -6px !important;
    margin-right: -6px !important;
    margin-top: -6px !important;
    position: relative !important;
    width: 16px !important;
  }
  
  .frame .frame-wrapper-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    padding: 12px 0px;
    position: relative;
  }
  
  .frame .div-4 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 24px 24px;
    position: relative;
    width: 306px;
  }
  
  .frame .card-layout {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    height: 117.08px;
    position: relative;
  }
  
  .frame .master-card-layout {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .frame .master-card {
    align-items: center;
    background-color: white;
    border-radius: 8px;
    box-shadow: var(--shadow-2);
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 88px;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    position: relative;
    width: 141px;
  }

  .frame .master-card:hover {
    box-shadow: var(--shadow-0);
    border: 1px solid rgb(41 135 244 / var(--tw-bg-opacity));
    cursor: pointer;
  }
  
  .frame .group {
    height: 20.03px;
    position: relative;
    width: 23px;
  }
  
  .frame .text-wrapper-2 {
    color: var(--newblack);
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .frame .div-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 4px;
    position: relative;
    width: 100%;
  }
  
  .frame .text-wrapper-3 {
    color: var(--newblack);
    font-family: "Lato", Helvetica;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame .plus-circle {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }
  
  .frame .text-wrapper-4 {
    color: var(--newblack);
    font-family: "Inter", Helvetica;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -1.5px;
    margin-right: -1.5px;
    position: relative;
    width: fit-content;
  }