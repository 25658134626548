.p-inputnumber {
    &.p-invalid.p-component > .p-inputtext {
        @include invalid-input();
    }

    .p-inputnumber-input{
        border-radius: .75rem;
        height: 1.125rem;
        width: 2.5rem;
        font-size: 10px;
        padding: .1875rem 2px;
        box-shadow: none;
        border-color: #B1B8CB;
        color: #B1B8CB;
        text-align: center;
    }
}