.p-fileupload {
    .p-fileupload-buttonbar {
        background: $panelHeaderBg;
        padding: $panelHeaderPadding;
        border: $panelHeaderBorder;
        color: $panelHeaderTextColor;
        border-bottom: 0 none;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
        gap: $inlineSpacing;
        
        // .p-button {
        //     margin-right: $inlineSpacing;
        // }
    }

    .p-fileupload-content {
        background: $panelContentBg;
        padding: $fileUploadContentPadding;
        border: $panelContentBorder;
        color: $panelContentTextColor;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }

    .p-progressbar {
        height: $fileUploadProgressBarHeight;
    }

    .p-fileupload-row {
        display: flex;
        align-items: center;
        margin-bottom: $fileUploadPreviewMargin;
        > div {
            flex: 0 0 auto;
            width: auto;
            padding: 0;
            // &:first-child {
            //     padding: $tableBodyCellPaddingSm;
            //     flex-grow: 1;
            // }
        }
        // div:first-child {
        //     padding: $tableBodyCellPaddingSm;
        //     flex-grow: 1;
        // }
        span {
            font-size: $fontSizeXs;
            &.p-fileupload-file-badge {
                margin: 0 $fileUploadPreviewMargin;
                font-size: $badgeFontSizeSm;
                line-height: $badgeHeightSm;
                height: $badgeHeightSm;
            }
        }
        img {
            width: $fileUploadPreviewWidth;
            margin-right: $fileUploadPreviewMargin;
            & + div {
                flex-grow: 1;
            }	
        }
    }

    .p-fileupload-filename {
        font-size: $fontSizeXs;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.p-fileupload-advanced {
        .p-message {
            margin-top: 0;
        }
    }
}
